import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { HelmetProvider } from 'react-helmet-async';
// import "./scss/custom-variables.scss"
// import "./scss/custom.scss"

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './ContextAPI/AppContextProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));


if (process.env.REACT_APP_ENV !== 'development') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}



root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
