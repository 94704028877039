import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/dashboard-components/Sidebar'
import DashHead from '../../components/dashboard-components/dashHead'
import { getSocialLinks, updateSocialLinks } from '../../ContextAPI/APIs/api'
import { useToast } from '../../ContextAPI/Components/notify'
import { useLoader } from '../../ContextAPI/Components/loaderContext'
import { useAuth } from '../../ContextAPI/Components/auth'

const SocialLinks = () => {

    const { toastAlert } = useToast()
    const { showLoader, hideLoader } = useLoader()
    const { user, getLoginUser } = useAuth();


    const [Links, setLinks] = useState({
        socialLabel: '',
        facebook: '',
        instagram: '',
        tiktok: '',
        'x-twitter': '',
        youtube: '',
        spotify: '',
        'apple-music': '',
        tidal: '',
        'amazon-music': '',
        soundcloud: '',
    })

    let socialLinks = [
        {
            icon: "facebook",
            label: "Facebook"
        },
        {
            icon: "instagram",
            label: "Instagram"
        },
        {
            icon: "tiktok",
            label: "Tiktok"
        },
        {
            icon: "x-twitter",
            label: "X Twitter"
        },
        {
            icon: "youtube",
            label: "Youtube"
        },
        {
            icon: "spotify",
            label: "Spotify",
        },
        {
            icon: "apple-music",
            label: "Apple Music",
        },
        {
            icon: "tidal",
            label: "Tidal",
        },
        {
            icon: "amazon-music",
            label: "Amazon Music"
        },
        {
            icon: "soundcloud",
            label: "Soundcloud"
        },

    ]

    

    const getLinks = async () => {
        showLoader()

        let thisUser = user;
        if (thisUser == null) thisUser = await getLoginUser()


        const res = await getSocialLinks({ type: thisUser.username })
        const data = res.message
        if (!data) {
            hideLoader()
        } else {
            setLinks({
                socialLabel: data.label ? data.label : "",
                facebook: data.fb ? data.fb : '',
                instagram: data.insta ? data.insta : '',
                tiktok: data.tiktok ? data.tiktok : '',
                'x-twitter': data.twitter ? data.twitter : '',
                youtube: data.youtube ? data.youtube : '',
                spotify: data.spotify ? data.spotify : '',
                'apple-music': data.appleMusic ? data.appleMusic : '',
                tidal: data.tidal ? data.tidal : '',
                'amazon-music': data.amazonMusic ? data.amazonMusic : '',
                soundcloud: data.soundCloud ? data.soundCloud : '',
            })

            hideLoader()
        }
    }

    const submit = async (e) => {
        e.preventDefault();
        showLoader()

        const res = await updateSocialLinks({
            label: Links.socialLabel,
            fb: Links.facebook,
            insta: Links.instagram,
            tiktok: Links.tiktok,
            twitter: Links['x-twitter'],
            youtube: Links.youtube,
            spotify: Links.spotify,
            appleMusic: Links['apple-music'],
            tidal: Links.tidal,
            amazonMusic: Links['amazon-music'],
            soundCloud: Links.soundcloud,
        })

        toastAlert(res.message, res.success ? "success" : "error")

        hideLoader()
    }

    useEffect(() => {
        getLinks();
    }, [])



    return <>
        <section>

            <DashHead />

            <div className="container-admin">


                <div className="row mb-5">
                    <div className="col-lg-4">
                        <Sidebar />
                    </div>
                    <div className="col-lg">
                        <h2 className=''>Social Links</h2>
                        <form className="d-grid gap-3 my-3" action="">
                            <div className="form-floating">
                                <input type="text" className='form-control' name="socialLinksLabel" id="socialLinksLabel" placeholder='Social Links Label' value={Links.socialLabel} onChange={(E) => setLinks({ ...Links, socialLabel: E.target.value })} />
                                <label htmlFor="socialLinksLabel">Social Links Label</label>
                            </div>

                            {
                                socialLinks.map((e, i) => {
                                    return <div key={i} className={`form-floating form-social social-icon-${e.icon}`}>
                                        <input type="text" class="form-control" id={e.icon} value={Links?.[e.icon]} onChange={(E) => setLinks({ ...Links, [e.icon]: E.target.value })} placeholder={e.label} />
                                        <label for={e.icon}>{e.label}</label>
                                    </div>
                                })
                            }

                            <button onClick={(e) => submit(e)} className='btn btn-primary'>Save</button>
                        </form>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default SocialLinks
