import { useSearchParams } from "react-router-dom";

const searchParams = new URLSearchParams(window.location.search);
const ARTIST = searchParams.has('user') ? searchParams.get('user') : ""


/**
 * Here we are checking is 2nd index pathname is equal to admin? 
 * if YES, means we are at dashboard
 * if NO, means we are at visitor page and then 
 *  => if there is no artist name in url then redirect to default artist
 */ 
const defaultCreator = "yarleygmusic";
if (ARTIST == "" && (window.location.pathname).split("/")[1] != "admin" && (window.location.pathname).split("/")[1] != "sign-in" && (window.location.pathname).split("/")[1] != "sign-up") window.location.href = `/?user=${defaultCreator}`


const API_URL = `https://api.zintunes.com`;
const FILE_URL = `https://api.zintunes.com/assets/files/${ARTIST}`;


// const API_URL = `http://localhost:5000`;
// const FILE_URL = `http://localhost:5000/assets/files/${ARTIST}`;


// const firstMetaId = '1848482259279768';
// const secondMetaId = '649425104171128'
// const googleTrackingId1 = 'G-R3JCJWHPS5';
// const googleTracckingId2 = 'G-ES7YR58TT7';

// let MetaId = userName === "hassan799" ? '1848482259279768' : '649425104171128'
// const TRACKING_ID = userName === "hassan799" ? "G-R3JCJWHPS5"  : "G-ES7YR58TT7";

export {
    API_URL,
    FILE_URL,
    ARTIST,
    defaultCreator,searchParams,
    // firstMetaId,secondMetaId,
    
    // googleTrackingId1,googleTracckingId2

}