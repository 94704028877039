import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';



/**
 * Tracks a custom event in Google Analytics (GA4) and Meta Pixel.
 *
 * @param {string} category - GA4 Event Category (e.g., "User Interaction").
 * @param {string} action - Event Name (e.g., "Button Click").
 * @param {string} label - Event Label (e.g., "Signup Button").
 */


let isGAInitialized = false;

const isPixelInitialized = () => {
    return typeof window.fbq === 'function' && window.fbq.getState && window.fbq.getState().pixels.length > 0;
};

const MetaInit = (MetaId,userName) =>{
    // username here will be the artist name who album or id is opened 
    if(MetaId !== undefined && MetaId !== null ){
        console.log('metaaaaa')
        ReactPixel.init(MetaId, userName, { autoConfig: true, debug: true });
    }
    return console.log('returned meta id')
}


const GoogleAnalyticsId = (trackingId) => {
    if (!isGAInitialized && (trackingId != undefined && trackingId !== null )) {
       
       console.log('gaaaaa')
        ReactGA.initialize(trackingId);
        isGAInitialized = true;
        console.log('Google Analytics initialized.');
    }
};

// page view in metaPixel and google analytics 

const TrackPageView = () => {

    if(isPixelInitialized()){
         ReactPixel.pageView();

     }
     if (isGAInitialized) {

         ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
     }
    };




const handleClick= (category = "General", action = "click", label = "Button") => {
    // Track event in Meta Pixel (Facebook Pixel)
    
    if(isPixelInitialized()){
        console.log('pixelssssssssssss',category,action,label)

        ReactPixel.trackCustom(label, {
            click_type:label,
            content_action: action,
            content_category: category,
           
        });
    }

    // Ensure Google Analytics (GA4) is initialized before tracking
    if (isGAInitialized) {
        console.log('analytics')

        ReactGA.event({
            category: category,
            action: label,
            label: action,
        });
    } else {
        console.warn("Google Analytics is not initialized. Event not tracked:", { category, action, label });
    }

    console.log(`📊 Event Tracked → Category: ${category}, Action: ${action}, Label: ${label}`);
};





const ScrollDetector= (category = "General", action = "click", label = "Button") => {
    // Track event in Meta Pixel (Facebook Pixel)
    if(isPixelInitialized()){
        console.log('pixelssssssssssss')

        ReactPixel.trackCustom(label, {
            click_type:label,
            content_action: action,
            content_category: category,
           
        });
    }

    // Ensure Google Analytics (GA4) is initialized before tracking
    if (isGAInitialized) {
        console.log('analytics')

        ReactGA.event({
            category: category,
            action: action,
            label: label,
        });
    } else {
        console.warn("Google Analytics is not initialized. Event not tracked:", { category, action, label });
    }

    console.log(`📊 Event Tracked → Category: ${category}, Action: ${action}, Label: ${label}`);
};

export { handleClick ,MetaInit,
    GoogleAnalyticsId,


    isGAInitialized,
    isPixelInitialized,
    TrackPageView,ScrollDetector
};
