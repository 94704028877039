import TagManager from 'react-gtm-module';
import ReactGA from "react-ga4";


// export const GTM_CONTAINER_ID = '';
// const GA_TRACKING_ID = 'G-87Z9F447XC';

// export const GTM_CONTAINER_ID = 'GTM-WXD9MZFQ';
// export const GA_TRACKING_ID = 'G-MLS0NBFNWG';

// Hassan G-1
export const GTM_CONTAINER_ID = 'GTM-TRX276M8';
// export const GA_TRACKING_ID = 'G-C0RTCJXHDG';

// Google tag (gtag.js)
{/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-C0RTCJXHDG"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-C0RTCJXHDG');
</script> */}

// Google Tag Manager
{/* <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TRX276M8');</script> */}
// End Google Tag Manager 


// Google Tag Manager (noscript) 
{/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TRX276M8"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> */}
// End Google Tag Manager (noscript) 

// Hassan G-2
// export const GTM_CONTAINER_ID = '';
// export const GA_TRACKING_ID = '';


export const initGA = (GA_TRACKING_ID) => {
    ReactGA.initialize(GA_TRACKING_ID);
};

export const initGTM = (GTM_CONTAINER_ID) => {
    TagManager.initialize({ gtmId: GTM_CONTAINER_ID });
};

export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export const logEvent = (category, action, label, value) => {
    ReactGA.event({
        category,
        action,
        label,
        value,
    });
};


export const handleFireEvent = (e, name) => {

    console.log("nameeeeee",name)

    // Handle button click logic here
    // Optionally, you can track an event using GTM
    TagManager.dataLayer({
        dataLayer: {
            event: name,
            eventCategory: 'Click', // Optional category for better organization
            eventAction: name, // The button name or identifier
            event_label: name, // The button name or identifier
        },
    });

    logEvent('User Interaction', name, name, 1);

};